.empty{
    width: 100%;
    height: 100%;
 
    &-content{
       
        margin-top: var(--spacing-xl) ;
        width: 100%;
    }
  
    &-text{
        text-align: center;
    }
    &-icon{
        width: 100%;
        display: flex;
        justify-content: center;
        svg{
            height: 100px;
            width: 100px;
        }
    }
}