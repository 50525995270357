.input {
  border: 1px solid var(--border-color2);
  padding: 8px;
  border-radius: 4px;
  width: 100%;
  padding: var(--spacing-xs) var(--spacing-m);
  background-color: var(--color-form-input);
  color: var(--color-form-input-text);
  border-radius: var(--radius-md);
}
.input--error {
  border-color: var(--red);
}
.error-message {
  color: var(--red);
  font-size: var(--font-size-small);
}

.input__container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
  label {
    font-size: var(--font-size-medium);
    padding-left: var(--spacing-xs);
  }
}
.input{
&__info-message{
  color:var(--red);
  font-size: var(--font-size-small);
}
}