.block__files {
  display: flex;
  flex-direction: column;
  gap: 10px; // Add spacing between files

  .file__wrap {
    display: flex;
    align-items: center;
    padding: 10px;

    border-radius: 8px;

    gap: 12px;

    @media (max-width: 768px) {
      flex-direction: column; // Stack content vertically
      align-items: flex-start; // Align items to the left
    }
  }

  .file__icon {
 flex-shrink: 0; // Prevent icon from shrinking
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    svg {
      width: 40px;
      height: 40px;
    
    }
    @media (max-width: 768px) {
     width: 100%;
     svg {
      width: 70px;
      height: 70px;
    
    }
    }
  }

  .name-file {
    flex-grow: 1; // Allow file name to take remaining space
    font-size: 14px;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  .btn__wrap {
    display: flex;
    gap: var(--spacing-m);
    @media (max-width: 768px) {
      width: 100%; // Full-width button
      margin-top: 10px;
    }

    button {
      font-size: 14px;
      width: 100%; // Adjust button width on smaller screens
    }
  }
}
