.carousel {
  position: relative;
  width: 100%; /* Занимает 100% ширины родителя */
 
  overflow: hidden;
  background: var( --block-bg);
  border-radius: var(--radius-lg);
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* Стандартный синтаксис */
    /* Для Смартфонов */
    display: grid;
    grid-template-rows: 1fr 60px;
  @media screen and (max-width: 768px) {
    min-height: 450px;
  }

  /* Для планшетов */
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    min-height: 550px;
  }

  /* Для ПК */
  @media screen and (min-width: 1200px) {
   min-height: 650px;
  }
  &__bottom-bar{
    display: flex;
    gap: var(--spacing-m);
    align-items: center;
    justify-content: space-around;
  }
}

.carousel-track {
  width: 100%; /* Занимает 100% ширины родителя */
  height: calc(100% - 40px);
  position: relative;
}

.carousel-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease, visibility 1s ease;
}

.carousel-slide.active {
  opacity: 1;
  visibility: visible;
}

.carousel-button {
  // position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  background: var(--accent-color);
  color: var( --button-text);
  border: none;
 
  z-index: 10;
  cursor: pointer;
 height: 40px;
 width: 40px;
  border-radius: var(--radius-md);
  display: flex;
  align-items: center;
  justify-content: center;
  svg{
    width: 25px;
    height: 25px;
  }
  &:hover {
    background: var(--accent-color-hover)
  }
  // &-left {
  //   left: 10px;
  // }
  // &-right {
  //   right: 10px;
  // }
}







.carousel-dots {
  // position: absolute;
  // bottom: 10px;
  // left: 50%;
  // transform: translateX(-50%);
  display: flex;
  justify-content: center;
 align-items: center;
 padding: 10px;
  height: 60px;
  // background-color: rgb(0, 0, 0);
  border-radius: 21px;
}

.dot {
  height: 20px;
  width: 20px;
  margin: 0 5px;
  background: #bbb;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;
  transition: width 0.5s;
}

.dot.active {
  background: var(--accent-color);
 
}
.button__group{
  display: flex;
  gap: var(--spacing-m);
}