[data-theme="white-black"]{
    /* Основные цвета */
    --red: #ff0000; /* Основной красный */
    --dark-red: #000000; /* Темный красный */
   
    /* Цвета для кнопок */
    --accent-color: #000000; /* Фон кнопки */
    --button-text: #ffffff; /* Текст на кнопке */
    --accent-color-hover: #000000; /* Фон кнопки при наведении */

 // моно цвета
 --btn-color-alt: #1C1C1E;
 --btn-color-alt-hover: #000000;
 --btn-text-alt: #e4dbdb;




    --border-color1: #000;
    --border-color2: #000000;
    --border-color3: #000;
    /* Цвета меню */
    --menu-item-text: #ffffff; /* Цвет текста элементов меню */
   
    /* Цвета списков */
    --list-item-text: #ffffff; /* Цвет текста в списках */
   
    /* Фоны */
    --background-main: #ffffff; /* Основной фон */
    --background-alt: #ffffff; /* Альтернативный фон */
    --panel-bg: #ffffff; /* Фон панели */
    --panel-item-bg: #000000; /* Фон элементов панели */
    --block-bg: #fffdfd; /* Фон блоков */
   
    /* Тени */
    --shadow-color-1: rgb(255, 255, 255);
    --shadow-color-2: rgb(255, 255, 255);
   
    /* Цвета текста */
    --font-color-light: #000000;
   
    /* Цвета input */
    --color-form-input: #ffffff;
    --color-form-input-text: #000000;
   
    --link-color: rgb(0, 0, 0);
    /* Цвет для QR кодов */
    --qr-color: rgb(0, 0, 0); /* Цвет QR кода */
   }