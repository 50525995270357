.feedback {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
 
  .Messages{
    position:fixed;
    bottom: 50%;
    padding: var(--spacing-m);
    left: 50%;
    transform: translate(-50%,50%);
    background-color: rgb(255, 255, 255);
   
    display: flex;
    flex-direction: column;
    z-index: 100;
    align-items: end;
    gap: var(--spacing-m);
    border-radius: var(--radius-lg);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    @media screen and (max-width: 768px) {
      width: calc(100% - var(--spacing-xs) * 2);
    }
  }
  h1 {
    margin-bottom: var(--spacing-m);
    text-align: center;
  }
  &__form {
    @media screen and (max-width: 768px) {
     
    width: 100%;
    .wrap__field__form {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-m);
    }
    }
    
    /* Для планшетов */
    @media screen and (min-width: 768px) and (max-width: 1024px) {
    
    }
    
    /* Для ПК */
    @media screen and (min-width: 1200px) {
      
      width: 800px;
      .wrap__field__form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: var(--spacing-m);
      }
      }
   
    padding: var(--spacing-m);
    border-radius: var(--radius-lg);
    background-color: var( --block-bg);
    color: var(--font-color-light);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);
   
    height: min-content;
  }
}

