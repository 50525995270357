.error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    // background-color: #f8f9fa;
    // color: #333;
  
    .error-code {
      font-size: 5rem;
      font-weight: bold;
      margin: 0;
      color: #dc3545;
    }
  
    .error-message {
      font-size: 1.5rem;
      margin: 10px 0;
    }
  
    .error-link {
      margin-top: 20px;
      font-size: 1.2rem;
      color: #007bff;
      text-decoration: none;
  
      &:hover {
        text-decoration: underline;
      }
    }
  }
  