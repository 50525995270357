.Feedback{
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-m);
  &__title{
text-align: center;    
margin-bottom: var(--spacing-l);
  }
}
.feedback_form-button {
  display: flex;
  gap: var(--spacing-m);
  justify-content: end;
 
}
