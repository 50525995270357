[data-theme="black-white"]{
    /* Основные цвета */
    --red: #ff0000; /* Основной красный */
    --dark-red: #ffffff; /* Темный красный */
   
    /* Цвета для кнопок */
    --accent-color: #ffffff; /* Фон кнопки */
    --button-text: #000000; /* Текст на кнопке */
    --accent-color-hover: #ffffff; /* Фон кнопки при наведении */



     // моно цвета
  --btn-color-alt: #e5e5ec;
  --btn-color-alt-hover: #e2dede;
  --btn-text-alt: #000000;

    --border-color1: #000;
    --border-color2: #ffffff;
    --border-color3: #ffffff;

    /* Цвета меню */
    --menu-item-text: #000000; /* Цвет текста элементов меню */
   
    /* Цвета списков */
    --list-item-text: #000000; /* Цвет текста в списках */
   
    /* Фоны */
    --background-main: #000000; /* Основной фон */
    --background-alt: #000000; /* Альтернативный фон */
    --panel-bg: #000000; /* Фон панели */
    --panel-item-bg: #ffffff; /* Фон элементов панели */
    --block-bg: #000000; /* Фон блоков */

   
    /* Тени */
    --shadow-color-1: rgb(0, 0, 0);
    --shadow-color-2: rgb(0, 0, 0);
   
    /* Цвета текста */
    --font-color-light: #ffffff;
   
    /* Цвета input */
    --color-form-input: #000000;
    --color-form-input-text: #ffffff;
   
    --link-color: rgb(0, 0, 0);
    /* Цвет для QR кодов */
    --qr-color: rgb(255, 255, 255); /* Цвет QR кода */
   }