.AccessibilityUI{
width: 100%;
min-height: 200px;
background-color: rgb(245, 240, 240);
padding: var(--spacing-l);
position: relative;
border-radius: var( --radius-lg);
margin-bottom: var(--spacing-l);

&__block-wrap{
    width: 100%;
    display: flex;
    gap: var(--spacing-xs);
    align-items: center;
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: start;
    }
}
}

// .btn__exit{
//     position: absolute;
//     right: var(--spacing-l);
//     bottom: var(--spacing-l);
// }