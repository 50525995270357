.Header {
  background-color: var(--background-main);
  width: 100%;
  height: var(--header-height);
  grid-area: header;
  top: 0;
  position: sticky;
  z-index: 99;

&__logo{
  user-select: none;
  &-wrap{
    width: 100%;
    display: flex;
    justify-content: start;
  }
  padding:var(--spacing-m) ;

  span{
    font-size: 36px;
    font-weight: bold;
    color: var(--font-color-light);
  }
  .first{
    color: var(--accent-color);
  }
 
}
.content-container{
  height: 100%;
  display: flex;
  align-items: center;
  
  justify-content: end;
  gap: var(--spacing-m);
}

}
