@import "themes/light.scss";
@import "themes/dark.scss";
@import "themes/BlackWhite.scss";
@import "themes/WhiteBlack.scss";
@import "themes/BrownBeige.scss";
@import "themes/DarkBlueSky.scss";
@import "themes/GreenBrown.scss";

@import "whitespace.scss";
@import "font-size.scss";
@import "layout.scss";

@import "kern.scss";
@font-face {
  font-family: "Montserrat";
  src: url(./../../shared/assets/fonts/Inter/Inter-Medium.otf);
}
/* Safari */
*::selection {
  background: var(--accent-color-hover); 
  color: var(--button-text);
}
/* Firefox */
*::-moz-selection {
  background: var(--accent-color-hover); 
  color: var(--button-text);
}
/* Стилизация скроллинга */

*::-webkit-scrollbar {
  width: 3px;
  height: 3px;
 
}

*::-webkit-scrollbar-thumb {
  background: var(--accent-color-hover);
  border-radius: 2px;
}

*::-webkit-scrollbar-track {
  background: var(--background-main);
}

*::-webkit-scrollbar-track-piece {
  background: var(--background-main);
}

body {
  // font-family: 'Open Sans', sans-serif;
  // line-height: 1.5;
  // font-weight: 400;
  margin: 0;

  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;

  overflow-x: hidden;
  background-color: var(--background-main);
}

body,
html,
#root {
  height: 100%;
}
h1 {
  font-size: var(--font-size-xxl);
}

h2 {
  font-size: var(--font-size-xl);
}

h3 {
  font-size: var(--font-size-large);
}

h4 {
  font-size: calc(var(--font-size-large) * 0.9);
}

h5 {
  font-size: var(--font-size-medium);
}

h6 {
  font-size: calc(var(--font-size-medium) * 0.875);
}
.app {
  height: 100%;
}
.shadow__style {
  // -webkit-box-shadow: 0 1px 1px var(--shadow-color-1), 0 2px 4px var(--shadow-color-2);
  // box-shadow: 0 1px 1px var(--shadow-color-1), 0 2px 4px var(--shadow-color-2);
  border: 0.0625rem solid var(    --border-color3);
}

*,
*::before,
*::after {
  font-family: Montserrat;
  box-sizing: border-box;
}

/* ol,
ul {
    list-style: none;
    padding: 0;
} */

body,
h1,
h2,
h3,
h4,
h5,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

// h1,
// h2,
// h3,
// h4,
// h5 {
//     font-weight: 400;
// }

input,
button {
  outline: none;
}

a {
  text-decoration: none;
}

* {
  font-size: var(--font-size-medium);
}
.app{
  position: relative;
  height: max-content;
}
// /* для Chrome/Edge/Safari */
.content-container{
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @media screen and (min-width: 1220px) {
    padding:0 20px  ;
  }
}