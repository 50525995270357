.Page-Article {
  display: flex;
  flex-direction: column;
  width: 100%;
 
  gap: var(--spacing-m);
  .Tape {
    display: flex;
    flex-direction: column;
    row-gap: var(--spacing-m);
  }
}
