.CardRead {
  background-color: var(--block-bg);

  display: flex;

  justify-content: space-between;
  color: var(--font-color-light);

  overflow: hidden;
  row-gap: 10px;
  border-radius: var(--radius-lg);
  @media screen and (max-width: 768px) {
    flex-direction: column;
    &_content {
      flex-grow: 1;
      width: 100%;
      // display: flex;
      justify-content: start;
      padding: 0 var(--spacing-l) var(--spacing-l) var(--spacing-l);
    }
  }

  /* Для планшетов */
  // @media screen and (min-width: 768px) and (max-width: 1024px) {

  // }

  /* Для ПК */
  @media screen and (min-width: 767px) {
    &_content {
      flex-grow: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      padding: var(--spacing-m) var(--spacing-l);
    }
  }

  // box-shadow: 0 12px 20px var(--shadow-color), 0 0 2px var(--shadow-color);
  &__img__wrap {
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &_top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-m);
  }
  &__type {
    border-radius: var(--spacing-xs);
    background-color: #eb8585;
    color: white;

    padding: var(--spacing-xxs) var(--spacing-m);
  }
  &_title {
    font-weight: bold;
    margin-bottom: var(--spacing-m);
  }
  img {
    width: 100%;
  }
  &_bottom-bar {
    padding: var(--spacing-xs);

    display: flex;
    justify-content: end;

    // border-top: 1px solid #585858;
  }
  &_wrap_date {
    height: 100%;
    display: flex;
    align-items: center;
  }
  &_text-container {
    width: 100%;

    .truncate-text {
      overflow: hidden;
      display: -webkit-box;
      line-clamp: 5;
      -webkit-box-orient: vertical;
      line-height: 1.3em;
      height: 6.5em;
    }
  }
}
