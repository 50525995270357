.search-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: start;
  justify-content: center;
  z-index: 1000;
  padding-top: var(--spacing-xl);
  // backdrop-filter: blur(10px);
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  width: 600px;

  display: flex;
  flex-direction: column;
  max-height: 100%;
  @media (max-width: 767px) {
    width: 100%;
    padding: 20px;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;

  .search-input {
    flex-grow: 1;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }

  .close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
}

.tabs {
  display: flex;
  gap: 12px;
  margin-top: 12px;

  span {
    padding: 6px 12px;
    cursor: pointer;
  
    color: #555;

    &.active-tab {
        border-bottom: 2px solid var(--accent-color);
     
    }
  }
}

.popular-queries {
  margin-top: 16px;

  h3 {
    font-size: 16px;
    margin-bottom: 8px;
  }

  ul {
    list-style: none;
    padding: 0;

    .query-item {
      padding: 6px 0;
      font-size: 14px;
      color: #007bff;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}



.nav-search{
    &__nav{
        display: flex;
        justify-content: center;
        gap: var(--spacing-xs);
        border-top: 1px solid var(--border-color2);
        padding-top: var(--spacing-s);
        li,kbd{
            font-size: var( --font-size-small);
            color: var(--form-text);
        }
   
      
    }
}