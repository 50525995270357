.radioGroup {
  label {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.375em;
    /* Accessible outline */
    /* Remove comment to use */
    /*
          &:focus-within {
              outline: .125em solid var(--accent-color);
          }
        */
    input {
      position: absolute;
      left: -9999px;
      &:checked + span {
        // background-color: mix(#fff, var(--accent-color), 84%);
        &:before {
          box-shadow: inset 0 0 0 0.4375em var(--accent-color);
        }
      }
    }
    span {
      display: flex;
      align-items: center;
      padding: 0.375em 0.75em 0.375em 0.375em;
      border-radius: 99em; // or something higher...
      transition: 0.25s ease;
      &:hover {
        // background-color: mix(#fff, var(--accent-color), 84%);
      }
      &:before {
        display: flex;
        flex-shrink: 0;
        content: "";
        background-color: #00000000;
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
        margin-right: 0.375em;
        transition: 0.25s ease;
        box-shadow: inset 0 0 0 0.125em var(--accent-color);
      }
    }
  }
}
