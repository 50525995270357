.block__images{
    width: 100%;
    height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-m);
    img{
        width: 100%;
        height: 100%;
    }
}