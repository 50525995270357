.home {
  color: var(--font-color-light);
  display: flex;
  flex-direction: column;
  &__content{
display: grid;
gap: var(--spacing-m);
  }
 
}
.Cell{
  display: flex;
  flex-direction: column;
 
  gap: var(--spacing-m);
  img{
    max-width: 100%;
    border-radius: var(--radius-lg);
}
}
.table-page{
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
  // h2{
  //   margin-bottom:  var(--spacing-m);
  // }
}