.employee-card {
  display: flex;
  // align-items: center;

  width: 100%;
  flex-direction: column;

  &__photo {
    flex-shrink: 0;
    // width: 300px;
    // height: 300px;
    width: 100%;
    margin-bottom: var(--spacing-l);
    //   border-radius: 50%;
    object-fit: cover;

    img {
      border-radius: calc(var(--radius-sm));
    }
  }
  a{
    color: var(--font-color-light);
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);
   
    &__name {
      font-size: 1.5rem;
      font-weight: bold;
      color: #333;
      margin: 0 0 8px;
    }

    &__position {
      font-size: 1.2rem;
      color: #555;
      margin: 0 0 4px;
    }

    &__education {
      font-size: 1rem;
      color: #777;
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;

    &__photo {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
}
