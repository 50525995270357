.banner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; // Элементы равномерно распределяются
  padding: var(--spacing-m) var(--spacing-l);
  background-color: var(--block-bg);
  color: var(--font-color-light);

  &__image-wrapper {
    max-width: 100%;
    overflow: hidden;

    margin-bottom: 15px;
    min-height: 200px; // Резервируем место для изображения
    display: flex;
    align-items: center;
  }

  &__image {
    max-height: 100%;
    max-width: 100%;
    display: block;
    // object-fit: cover;
  }

  &__content {
    margin-top: 10px;
    min-height: 50px; // Резервируем место под кнопку
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }

  &__link {
    text-decoration: none;
    display: block;
    width: 100%;
    max-width: 200px;
    text-align: center;
  }
}
