.layout {
  background-color: var(--background-main);
  min-height: 100vh;
  width: 100vw;
  align-items: start;
  display: grid;
  position: relative;
  row-gap:var(--spacing-m);
  column-gap: var(--spacing-l);
  &__content {
    min-height: 100vh;
  }

  @media (min-width: 1200px) {
    grid-template-columns: 350px 1fr;
    grid-template-areas:
    
      "nav main "
      "nav main "
      ". footer ";
    
    &__content {
      width: 100%;
      grid-area: main;
      
    }
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    grid-template-columns: 350px 1fr;
    grid-template-areas:
    
      "nav main "
      "nav main "
      ". footer ";
    &__content {
      width: 100%;
      grid-area: main;
     
    }
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-template-areas:
    
      "main "
      "main "
      "footer ";
    &__content {
      width: 100%;
      grid-area: main;
      padding: var(--spacing-s) var(--spacing-m);
    }
  }
}
// *::-webkit-scrollbar {
//     height: 12px;
//       width: 12px;
//     }
//    *::-webkit-scrollbar-track {
//     background-color: var(--background-main);
//    }
//   *::-webkit-scrollbar-thumb {
//        background-color: rgb(66, 40, 216);
//      border-radius: 5px;
//      border: 3px solid rgb(255, 255, 255);
//    }
