.image-view {
    &__image {
      cursor: pointer;
      max-width: 100%;
      height: auto;
    }
  
    &__fullscreen {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  