[data-theme="green-brown"] {
    // Зеленый на темно-коричневом
    /* Основные цвета */
    --red: #008000; /* Основной красный */
    --dark-red: #403000; /* Темный красный */
   
    /* Цвета для кнопок */
    --accent-color: #706000; /* Фон кнопки */
    --button-text: #ffffff; /* Текст на кнопке */
    --accent-color-hover: #605000; /* Фон кнопки при наведении */



     // моно цвета
  --btn-color-alt: #1C1C1E;
  --btn-color-alt-hover: #000000;
  --btn-text-alt: #e4dbdb;

  
    --border-color1: #d0c000;
    --border-color2: #d0c000;
    --border-color3: #d0c000;
    /* Цвета меню */
    --menu-item-text: #302000; /* Цвет текста элементов меню */
   
    /* Цвета списков */
    --list-item-text: #000088; /* Цвет текста в списках */
   
    /* Фоны */
    --background-main: #605000; /* Основной фон */
    --background-alt: #605000; /* Альтернативный фон */
    --panel-bg: #605000; /* Фон панели */
    --panel-item-bg: #d0c000; /* Фон элементов панели */
    --block-bg: #605000; /* Фон блоков */
   
    /* Тени */
    --shadow-color-1: rgba(60, 64, 67, 0);
    --shadow-color-2: rgba(9, 36, 51, 0);
   
    /* Цвета текста */
    --font-color-light: #d0c000;
   
    /* Цвета input */
    --color-form-input: #ffffff;
    --color-form-input-text: #000000;
   
    --link-color: rgb(0, 0, 0);
    /* Цвет для QR кодов */
    --qr-color: rgb(0, 0, 0); /* Цвет QR кода */
   }